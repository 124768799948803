import { APP_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import {
  bootstrapApplication,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { ChatRouting, EditorRouting, PeopleRouting, buyerSharedRouting } from '@app/frontend-ui';
import { environment } from '@app/frontend-core';
import { frontendProvider } from '@app/frontend-core';
if (environment.production) {
  enableProdMode();
}

import { ErrorHandler, Injectable } from '@angular/core';
import { Routes, provideRouter, withRouterConfig, withInMemoryScrolling } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {
    // afterNextRender(() => {
    //     try {
    //         window.addEventListener('vite:preloadError', (event) => {
    //             console.error('preload vite error here', event)
    //             // window.location.reload() // for example, refresh the page
    //         })
    //     } catch (error) {
    //         console.error('try to add vite:preloadError failed')
    //     }
    // })
  }

  handleError(error: any): void {
    console.error('your error', error.message, error);
    const chunkFailedMessage = /Loading chunk .*failed.*[.js\\)]/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    }
  }
}


const chatterWebRouting: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('frontend-ui/src/layout/main/main.layout').then(
        (c) => c.MainLayout,
      ),
    // canActivate: [LoginGuard],
    children: [
      {
        path: 'c',
        children: ChatRouting,
      },
      {
        path: 'd',
        children: EditorRouting,
      },
    ],
  },
];

export const routerProviders = [
  provideRouter(
    [
      ...buyerSharedRouting,
      ...chatterWebRouting,
      ...PeopleRouting,
      {
        path: 'not-found',
        loadComponent: () =>
          import('frontend-ui/src/app-buyer/pages/not-found/not-found.page.js').then(
            ({ NotFoundPage }) => NotFoundPage,
          ),
      },
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: '**', pathMatch: 'full', redirectTo: 'not-found' },
    ],
    withInMemoryScrolling({
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      // https://github.com/atscott/angular/blob/ecad53c616ee3e300a4aa6290e7c4493ede8a615/adev/src/app/app-scroller.ts
    }),
    // withRouterConfig({
      
    // }),
  ),
];




export const providers = [
  ...routerProviders,
  ...frontendProvider,
  { provide: APP_ID, useValue: 'serverApp' },
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  provideClientHydration(),
  // this one could lead to cache ???
  // withHttpTransferCacheOptions({
  //     includePostRequests: true
  // })
  // importProvidersFrom(),
  // ServiceWorkerModule.register('ngsw-worker.js', {
  //     enabled: environment.production,
  //     registrationStrategy: 'registerImmediately',
  // }),
];
