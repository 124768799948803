import { ChangeDetectorRef, Component, HostBinding, HostListener, Injector, ViewEncapsulation, afterNextRender, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment, ChatterStarter } from '@app/frontend-core';
import { ToastComponent } from '@app/frontend-ui';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent],
  template:  `
    <app-toast></app-toast>
    <router-outlet></router-outlet>
    <div id="tanstack-query-devtools"></div>
 
  `,
  styles: [`
    
    .tsqd-open-btn-container {
      bottom: 13rem!important;
    }
    
    `],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'chatter-web';
  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;
  chatterStarter = inject(ChatterStarter);
  notProduction = !environment.production;


  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll() {
  //   console.log(window.scrollY);
  // }

  cd = inject(ChangeDetectorRef);
  constructor(private injector: Injector) {
    
    afterNextRender(async () => { 
      await this.chatterStarter.start();
      effect(() => {
        const user = this.chatterStarter.peopleGear.myProfile();
        if (user?.userId) {
          // console.log('user found, checking onUser for subscription', user);
          this.chatterStarter.onUser();
        }
      }, { injector: this.injector });
    });
  }

  ngAfterViewInit() { }
}
